body {
	text-align: center;
}

.hidden {
	display: none;
}

#menu {
	width: 90%;
	background: rebeccapurple;
	color: white;
	font-size: 20px;
	padding: 5%;
}

#menu a {
	color: white;
	text-decoration: none;
}

#menu a:visited {
	color: white;
	text-decoration: none;
}



#progres {
	height: 10px;
	width: 100%;
	background-color: #98ccff;
}

#completed {
	height: 10px;
	background-color: #0f4bac;
	width: 0;
}

#scene {
	width: 90%;
	height: 50px;
	margin: 10px 5%;
	perspective: 600px;
}

.card {
	width: 100%;
	height: 100%;
	position: relative;
	transition: transform 0.5s;
	transform-style: preserve-3d;
	line-height: 50px;
}

.card__face {
	position: absolute;
	height: 100%;
	width: 100%;
	backface-visibility: hidden;
	border-radius: 10px;
	background: #CBE4FF;
	border: 2px solid #79d3ff;
}

.card__face--front {

}

.card__face--front::after {
	content: "La";
	position: absolute;
	left: 5px;
}

.card__face--back {

	transform: rotateY(180deg);
}

.card__face--back::after {
	content: "Cz";
	position: absolute;
	left: 5px;
}

.card.is-flipped {
	transform: rotateY(180deg);
}